<template>
  <v-card class="details-box elevation-1">
    <header class="headings mb-6">
      <h4 class="heading">
        {{ entity.name }}
      </h4>
      <CyButton
        variant="tertiary"
        theme="primary"
        icon="close"
        icon-only
        class="close-btn"
        @click.native="closeBox"/>
    </header>

    <section class="resource-path-section">
      {{ $t(`roles.${entity.code}`) }}
      <a
        class="cy-link"
        :href="$docLinks.permissions[entity.code]"
        target="_blank">{{ $t('learnMore') }}</a>
    </section>

    <section class="resource-path-section">
      <div class="resource-path-section__heading">
        {{ $t('headings.resourcePath') }}
        <CyTooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="help-icon"
              color="grey"
              v-on="on">
              help_outline
            </v-icon>
          </template>
          {{ $t('hints.resourcePath', { entityName: entity.name }) }}
        </CyTooltip>
      </div>
      <v-text-field
        :value="getResourcePath(entity.code)"
        class="text-body-1 mt-2 pt-0"
        readonly>
        <template slot="append">
          <CyCopyBtn
            left
            :copy-hint="getResourcePath(entity.code)"
            :copy-value="getResourcePath(entity.code)"/>
        </template>
      </v-text-field>
    </section>

    <section class="actions-section">
      <div class="actions-section__heading">
        <span>{{ $t('headings.actions') }}</span>
        <span>
          <CyButton
            v-if="editable && isCycloidEmployee"
            variant="secondary"
            class="toggle-all-btn"
            xs
            @click="toggleAllActions">
            <span v-if="areAllActionsChecked">
              {{ $t('uncheckAll' ) }}
            </span>
            <span v-else>
              {{ $t('checkAll' ) }}
            </span>
          </CyButton>

          <CyTooltip
            v-if="editable && !_.isEmpty(entity.children)"
            bottom>
            <template #activator="{ on }">
              <span
                class="ml-2"
                v-on="on">
                <CyButton
                  variant="secondary"
                  class="propagate-btn"
                  icon="call_split"
                  xs
                  :disabled="_.isEmpty(entity.children)"
                  @click="propagateActions">
                  {{ $t('propagate' ) }}
                </CyButton>
              </span>
            </template>
            {{ $t('propagateTooltip') }}
          </CyTooltip>
        </span>
      </div>
      <div
        v-for="( { description }, actionName, index ) in sortedLocalEntityActions"
        :key="`${entity.name}-${actionName}`"
        class="action">
        <v-checkbox
          :key="`${actionsKey}-${index}`"
          v-model="localEntity.actions[actionName].enabled"
          color="secondary"
          class="action__checkbox"
          :disabled="!editable || (!isCycloidEmployee && $route.name !== 'newApiKey')"
          :ripple="false"
          :label="actionName"
          :error="!canClose"
          @change="handleActionClick"/>
        <!-- TODO: FE#6436 set localEntity.isCustom once BE will return us flags for custom actions (for now it's good this div content results hidden) -->
        <div
          v-if="localEntity.isCustom"
          class="action__description"
          v-html="$sanitizeHtml(description)"/>
      </div>
    </section>

    <section
      v-if="!isRootEntity"
      class="resources-section">
      <span class="resources-section__heading">{{ $t('headings.resources') }}</span>
      <v-radio-group
        v-model="isAllResources"
        :disabled="!editable">
        <v-radio
          color="secondary"
          :label="$t('allResources')"
          :value="true"
          @change="selectAllResources"/>
        <v-radio
          color="secondary"
          :label="$t('specificResources')"
          :value="false"
          @change="handleSpecificResourcesChange"/>
      </v-radio-group>

      <CyInputsCombobox
        v-if="!isAllResources"
        v-model="comboboxResources"
        :disabled="!editable"
        persistent-hint
        :is-dropdown-enabled="false"
        :label="$t('headings.resources')"
        :hint="entity.name === 'service_catalog' ? $t('hints.resourcesServiceCatalog') : $t('hints.resources')"
        @input="handleSpecificResourcesChange"/>
    </section>
  </v-card>
</template>

<script>
import { faker } from '@faker-js/faker'
import { mapGetters } from 'vuex'
import CyCopyBtn from '@/components/copy-btn'
import CyInputsCombobox from '@/components/inputs/combobox'

const { datatype } = faker

export default {
  name: 'CyDetailsBox',
  components: {
    CyCopyBtn,
    CyInputsCombobox,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    actionsKey: `action-key-${datatype.number()}`,
    localEntity: {
      actions: {},
      resources: [],
    },
    comboboxResources: [],
    isAllResources: true,
  }),
  computed: {
    ...mapGetters('user', ['isCycloidEmployee']),
    actionsChecked () {
      return _.filter(this.localEntity.actions, ({ enabled }) => enabled).length
    },
    areAllActionsChecked () {
      const { actionsChecked, localEntity: { actions } } = this
      return _.size(actions) === actionsChecked
    },
    canClose () {
      const { actionsChecked, comboboxResources, isAllResources } = this
      return !(_.size(comboboxResources) > 0 && actionsChecked === 0) || isAllResources
    },
    isRootEntity () {
      return this.entity.code === 'organization'
    },
    sortedLocalEntityActions () {
      const { actions = {} } = this.localEntity
      const actionsKeys = _.keys(actions)
      const sortedActions = {}
      const crudActions = ['create', 'read', 'update', 'delete']

      for (const crudAction of crudActions) {
        if (actionsKeys.indexOf(crudAction) >= 0) sortedActions[crudAction] = actions[crudAction]
      }

      for (const action in actions) {
        if (!_.includes(crudActions, action)) sortedActions[action] = actions[action]
      }

      return sortedActions
    },
  },
  watch: {
    entity: {
      handler () {
        this.localEntity.actions = _.$get(this.entity, 'actions', {})
        this.localEntity.resources = _.$get(this.entity, 'resources', [])
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
    const { entity, entity: { resources = [] } } = this

    this.localEntity = _.pick(_.cloneDeep(entity), ['actions', 'resources'])
    this.comboboxResources = _.cloneDeep(resources)
    this.isAllResources = _.isEmpty(this.comboboxResources)
  },
  methods: {
    closeBox () {
      if (this.canClose) {
        this.$emit('close')
        this.updateEntity()
      }

      this.shakeErrors()
      this.emitShowWarning()
    },
    emitShowWarning () {
      const { actionsChecked, comboboxResources, isAllResources } = this
      const showActionsWarning = !isAllResources && actionsChecked === 0 && _.size(comboboxResources) > 0
      this.$emit('show-actions-warning', showActionsWarning)
    },
    getResourcePath (code) {
      if (code === 'organization') return code

      const codeChunks = _.split(code.substring(code.indexOf(':') + 1), ':')
      return `organization:${this.orgCanonical}:${_.join(codeChunks, ':*:')}`
    },
    handleActionClick () {
      this.emitShowWarning()
      this.updateEntity()
    },
    handleSpecificResourcesChange () {
      this.localEntity.resources = _.cloneDeep(this.comboboxResources)
      this.emitShowWarning()
      this.updateEntity()
    },
    selectAllResources () {
      this.comboboxResources = []
      this.localEntity.resources = []
      this.$emit('show-actions-warning', false)
      this.updateEntity()
    },
    shakeErrors () {
      this.actionsKey = `action-key-${datatype.number()}`
    },
    propagateActions () {
      this.$emit('propagate', { ...this.entity, ...this.localEntity })
    },
    toggleAllActions () {
      const { localEntity: { actions }, areAllActionsChecked } = this
      for (const action in actions) {
        actions[action].enabled = !areAllActionsChecked
      }
      this.updateEntity()
      this.emitShowWarning()
    },
    updateEntity () {
      this.$emit('change', { ...this.entity, ...this.localEntity })
    },
  },
  i18n: {
    messages: {
      en: {
        allResources: 'All @:resources',
        checkAll: 'Check all',
        headings: {
          actions: 'Actions',
          resourcePath: '@:Resource path',
          resources: '@:Resources',
        },
        hints: {
          resourcePath: 'This is the {entityName} @:resource path. Use it when defining manual actions.',
          resourcesServiceCatalog: '@:Resource to allow, specified with @:stack refs. Press TAB or ENTER to add a @:resource @:stack ref.',
          resources: '@:Resource canonicals to allow. Press TAB or ENTER to add a @:resource canonical.',
        },
        propagate: 'Propagate',
        propagateTooltip: 'Propagate actions (enabled and disabled) to all nested entities, overwriting their current values',
        specificResources: 'Specific @:resources',
        uncheckAll: 'Uncheck all',
      },
      es: {
        allResources: 'Todos los @:resources',
        checkAll: 'Marcar todo',
        headings: {
          actions: 'Acciones',
          resourcePath: 'Ruta del @:resource',
          resources: '@:Resources',
        },
        hints: {
          resourcePath: 'Esta es la ruta del recurso "{entityName}". Úselo al definir acciones manuales.',
          resourcesServiceCatalog: '@:Resource para permitir, especificado con @:stack refs. Presione TAB o ENTER para agregar un @:stack ref de @:resources.',
          resources: 'Canonicals de los @:resources que van a ser permitidos. Presione TAB o ENTER para agregar el canonical de un  @:resource.',
        },
        propagate: 'Propagar',
        propagateTooltip: 'Propaga las acciones (habilitadas y deshabilitadas) a todas las entidades anidadas, sobrescribiendo sus valores actuales',
        specificResources: '@:Resources especificos',
        uncheckAll: 'Desmarcar todo',
      },
      fr: {
        allResources: 'Toutes les spécifiques',
        checkAll: 'Tout vérifier',
        headings: {
          actions: 'Actions',
          resourcePath: 'Chemin de la @:resource',
          resources: '@:Resources',
        },
        hints: {
          resourcePath: 'Ceci est le chemin de la @:resource "{entityName}". Utilisez-le pour définir des actions manuelles.',
          resourcesServiceCatalog: '@:Resource à autoriser, spécifiée avec les @:stack refs. Appuyez sur TAB ou ENTRÉE pour ajouter une @:stack ref de @:resources.',
          resources: `Canonicals des @:resources à autoriser. Appuyez sur TAB ou ENTRÉE pour ajouter les canonicals d'une @:resource.`,
        },
        propagate: 'Propager',
        propagateTooltip: 'Propager les actions (activées et désactivées) à toutes les entités imbriquées, en écrasant leurs valeurs actuelles',
        specificResources: '@:Resources spécifiques',
        uncheckAll: 'Tout décocher',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin section-heading {
  padding-top: 4px;
  color: get-color("grey", "dark-1");
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
}

.details-box {
  height: 100%;
  min-height: 500px;
  padding: 16px;
  border-radius: 8px;

  ::v-deep .cy-copy-btn .v-icon,
  .help-icon {
    font-size: 21px;
  }

  .v-tooltip {
    cursor: default;

    ::v-deep .v-icon {
      width: 17px;
      height: 17px;
      margin-left: 4px;
      font-size: 20px;
    }
  }

  .help-icon {
    position: relative;
    top: -1px;
  }

  .headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .resource-path-section {
    margin-bottom: 30px;

    &__heading {
      @include section-heading;

      align-items: center;
    }
  }

  .resources-section {
    margin-bottom: 30px;

    &__heading {
      @include section-heading;
    }

    .v-input {
      &--radio-group {
        margin-top: 11px;
        padding-top: 0;
      }

      ::v-deep .v-label {
        color: get-color("primary");
      }
    }
  }

  .actions-section {
    margin-bottom: 30px;

    &__heading {
      @include section-heading;

      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;

      .propagate-btn.cy-btn,
      .toggle-all-btn.cy-btn {
        margin-top: -2.5px;
        padding-top: 5px;
        padding-bottom: 5px;

        ::v-deep .v-icon {
          transform: rotate(90deg);
          font-size: 12px;
        }
      }
    }

    .action {
      display: flex;
      flex-direction: column;

      &__description {
        margin-left: 33px;
        color: get-color("grey", "dark-1");
        font-size: 12px;
      }

      &__checkbox {
        flex-grow: 0;
        min-width: 125px;
        margin-top: 5px;

        ::v-deep {
          .v-input__slot {
            margin-bottom: 0;

            .v-label {
              color: get-color("primary");
            }
          }

          .v-messages {
            display: none;
          }
        }
      }
    }

    .v-input--has-state + .action__description {
      color: get-color("error");
    }
  }
}
</style>
