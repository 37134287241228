<template>
  <v-card class="permissions ma-1 elevation-1">
    <header class="permissions__header mb-4">
      <span class="permissions__heading">{{ $t('headings.entities') }}</span>
      <span class="permissions__heading">{{ $t('headings.actions') }}</span>
      <span class="permissions__heading">{{ $t('headings.resources') }}</span>
    </header>

    <section class="permissions__tree">
      <div>
        <v-checkbox
          v-model="allActions"
          color="secondary"
          class="check-all-checkbox"
          :ripple="false"
          :label="$t('allActions')"
          :disabled="!editable"
          @change="onAllActionsChange"/>
        <CyTooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="help-icon"
              color="grey"
              v-on="on">
              help_outline
            </v-icon>
          </template>
          {{ $t('allActionsTooltip') }}
        </CyTooltip>
      </div>

      <v-treeview
        hoverable
        open-all
        activable
        selected-color="secondary"
        item-key="code"
        :multiple-active="false"
        :items="entities"
        :active="[activeElementCode]">
        <template #prepend="{ item }">
          <span
            class="v-treeview-node__checkbox-wrapper"
            @click="openDetailsBox(item)">
            <v-checkbox
              class="v-treeview-node__checkbox"
              color="secondary"
              :disabled="!editable"
              :indeterminate="isIndeterminate(item.actions)"
              :input-value="areAllChecked(item.actions)"
              :ripple="false"
              @click="toggleAllActions(item)"/>
          </span>
        </template>
        <template #label="{ item }">
          <span
            :class="['v-treeview-node__item']"
            @click="openDetailsBox(item)">
            {{ item.name }}
          </span>
        </template>
        <template #append="{ item }">
          <span
            class="v-treeview-node__actions-wrapper"
            @click="openDetailsBox(item)">
            <span class="actions">
              {{ displayEnabledActions(item.actions) }}
            </span>
            <CyTag
              v-if="actionsWarning && item.code === activeElementCode"
              variant="error"
              icon-before="error"
              class="actions-warning">
              {{ $t('untranslated.none') }}
            </CyTag>
          </span>
          <span
            class="v-treeview-node__resources-selected-wrapper"
            @click="openDetailsBox(item)">
            <CyTag
              v-if="!_.isEmpty(item.resources) && !_.isEqual(item.resources, [])"
              variant="primary"
              class="resources-selected">
              {{ item.resources.length }} {{ $t('selected') }}
            </CyTag>
          </span>
          <span
            class="v-treeview-node__arrow-wrapper"
            @click="openDetailsBox(item)">
            <v-icon>chevron_right</v-icon>
          </span>
        </template>
      </v-treeview>
    </section>
  </v-card>
</template>

<script>
export default {
  name: 'CyPermissionsBox',
  props: {
    activeElementCode: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    entities: {
      type: Array,
      default: () => [],
    },
    actionsWarning: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    allActions: false,
  }),
  watch: {
    entities: {
      handler (entities) {
        this.allActions = this.isEverythingEnabled(entities)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    actionsChecked (actions) {
      return _(actions).pickBy('enabled').keys().sort().value()
    },
    areAllChecked (actions) {
      const actionsCount = _.keys(actions).length
      const actionsChecked = this.actionsChecked(actions).length

      return actionsChecked === actionsCount
    },
    hasFullCRUDAccess (actions) {
      const enabledActions = _.pickBy(actions, 'enabled')
      return _.$hasAll(enabledActions, ['create', 'read', 'update', 'delete'])
    },
    displayEnabledActions (actions) {
      const enabledActions = this.actionsChecked(actions)
      const enabledActionsCount = _.keys(enabledActions).length

      if (this.hasFullCRUDAccess(actions) || this.allActions) return this.$t('fullAccess')
      if (_.isEmpty(enabledActions)) return

      return enabledActionsCount > 2
        ? this.$t('someChecked', { enabledActionsCount })
        : _.join(enabledActions, ', ')
    },
    isEverythingEnabled (entities = []) {
      for (const { actions, children, resources } of entities) {
        const isAllActionsChecked = _.every(Object.values(actions), (action) => action.enabled)
        const isAllResources = _.isEmpty(resources)
        const isAllChildrenEnabled = _.isEmpty(children) ? true : this.isEverythingEnabled(children)

        if (!isAllActionsChecked || !isAllResources || !isAllChildrenEnabled) return false
      }
      return true
    },
    isIndeterminate (actions) {
      const actionsCount = Object.entries(actions).length
      const actionsChecked = this.actionsChecked(actions).length

      return actionsChecked > 0 && actionsChecked < actionsCount
    },
    onAllActionsChange () {
      this.toggleFullAccess(this.entities)
      this.$emit('refresh-selected-entity', this.activeElementCode)
    },
    openDetailsBox (entity) {
      if (this.actionsWarning) {
        this.$emit('open-details-failure')
        return
      }
      this.$emit('open-details', entity)
    },
    toggleAllActions (item) {
      const toggleValue = !this.areAllChecked(item.actions)
      for (const action in item.actions) item.actions[action].enabled = toggleValue
    },
    toggleFullAccess (entities = []) {
      const { allActions } = this

      for (const entity of entities) {
        entity.resources = []
        for (const action in entity.actions) entity.actions[action].enabled = allActions
        if (!_.isEmpty(entity.children)) this.toggleFullAccess(entity.children)
      }
    },
  },
  i18n: {
    messages: {
      en: {
        allActions: 'All actions',
        allActionsTooltip: 'Give unrestricted admin access.',
        fullAccess: 'Full access',
        headings: {
          actions: 'Actions',
          entities: 'Entities',
          resources: '@:Resources',
        },
        selected: 'selected',
        someChecked: '{enabledActionsCount} checked',
      },
      es: {
        allActions: 'Todas las acciones',
        allActionsTooltip: 'Da acceso de administrador sin restricciones.',
        fullAccess: 'Acceso completo',
        headings: {
          actions: 'Acciones',
          entities: 'Entidades',
          resources: '@:Resources',
        },
        selected: 'eligidas',
        someChecked: '{enabledActionsCount} marcadas',
      },
      fr: {
        allActions: 'Toutes les actions',
        allActionsTooltip: 'Accordez un accès administrateur illimité.',
        fullAccess: 'Accès total',
        headings: {
          actions: 'Actions',
          entities: 'Entités',
          resources: '@:Resources',
        },
        selected: 'choisies',
        someChecked: '{enabledActionsCount} choisies',
      },
    },
  },

}
</script>

<style lang="scss" scoped>
@mixin vertical-line {
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    bottom: 0;
    left: -11px;
    border-left: 1px solid get-color("grey");
    opacity: 1;
  }
}

@mixin horizontal-line {
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    right: calc(100% - 4px);
    left: -11px;
    border-top: 1px solid get-color("grey");
  }
}

@mixin active-class {
  border-radius: 4px;
  background-color: get-color("grey", "light-2");

  &:hover {
    background-color: get-color("grey", "light-3");
  }
}

.permissions {
  min-height: 500px;
  padding: 16px;
  border-radius: 8px;

  &__header {
    display: grid;
    grid-template-columns: [entity] auto [actions] 95px [resources] 155px [arrow] 24px;
  }

  &__heading {
    color: get-color("grey", "dark-1");
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;

    &:last-child {
      text-align: right;
    }
  }

  &__tree {
    margin: 0 -8px;

    .help-icon {
      position: relative;
      top: -5px;
      font-size: 21px;
    }
  }

  ::v-deep {
    .v-treeview-node {
      position: relative;

      &__append {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }

      &__content {
        max-height: 32px;
        margin-left: 0;
      }

      &__item {
        display: block;
        height: 100%;
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      &__level {
        width: 0;
      }

      &__toggle {
        display: none;
      }

      &__label {
        flex-basis: 180px;
        height: 32px;
        margin-left: 0;
        font-size: 14px;
        line-height: 30px;
      }

      &__checkbox-wrapper {
        position: relative;
        z-index: 1;
        cursor: pointer;
      }

      &__actions-wrapper,
      &__resources-selected-wrapper {
        display: flex;
        align-items: center;
        height: 32px;
        cursor: pointer;
      }

      &__actions-wrapper {
        width: 155px;

        .actions {
          color: get-color("grey", "dark-2");
          font-size: 12px;
        }
      }

      &__resources-selected-wrapper {
        justify-content: flex-end;
        width: 95px;

        .tag {
          font-size: 12px;
        }
      }

      &__arrow-wrapper {
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
        transition: opacity 0.2s ease;
        opacity: 0;
        cursor: pointer;
      }

      &__checkbox {
        display: block;
        flex: none;
        width: 24px;
        height: 24px;
        margin: 4px;
        padding: 0;

        input {
          z-index: -1;
          cursor: pointer;
        }
      }

      &--active {
        .v-treeview-node__arrow-wrapper {
          opacity: 1;
        }

        .v-treeview-node__content {
          @include active-class;
        }
      }

      &__root {
        min-height: 32px;
        padding-right: 0;
        padding-left: 0;
        transition: background-color 0.2s ease;
        border-radius: 4px;

        &::before {
          background-color: unset;
        }

        &:hover.v-treeview-node--active {
          &::before {
            opacity: 1;
          }
        }

        &:hover:not(.v-treeview-node--active) {
          background-color: get-color("primary", "light-5");

          .v-treeview-node__arrow-wrapper {
            opacity: 1;
          }
        }

        .v-treeview-node {
          margin-left: 26px;
        }
      }

      &__children {
        .v-treeview-node__root {
          @include horizontal-line;
          @include vertical-line;
        }
      }

      &--leaf {
        margin-left: 26px;

        &:only-child {
          .v-treeview-node__root {
            &:last-child::before {
              bottom: 16px;
            }
          }
        }
      }

      &:not(.v-treeview-node--leaf, :first-child) {
        margin-left: 26px;
      }

      &:not(:last-child, .v-treeview-node--leaf) {
        @include vertical-line;
      }

      & + .v-treeview-node:last-child {
        .v-treeview-node__root {
          &::before {
            max-height: 20px;
          }
        }
      }
    }
  }

  .check-all-checkbox {
    display: inline-block;
    height: 32px;
    margin: 0 0 8px;
    padding: 4px;
    border-radius: 0;

    ::v-deep {
      .v-label {
        color: get-color("primary");
        font-size: 14px;
        font-weight: 600;
      }

      .v-input {
        &__append-outer {
          margin-left: 5px;
        }

        &__slot {
          margin-bottom: 0;
        }
      }
    }
  }

  .help-icon {
    width: 24px;
    height: 24px;
    font-size: 20px;
    cursor: default;
  }
}
</style>
