import { render, staticRenderFns } from "./permissions-box.vue?vue&type=template&id=2af6e945&scoped=true&"
import script from "./permissions-box.vue?vue&type=script&lang=js&"
export * from "./permissions-box.vue?vue&type=script&lang=js&"
import style0 from "./permissions-box.vue?vue&type=style&index=0&id=2af6e945&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af6e945",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VCheckbox,VIcon,VTreeview})
