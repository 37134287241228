import { render, staticRenderFns } from "./policies-combobox.vue?vue&type=template&id=1fde3e5d&scoped=true&"
import script from "./policies-combobox.vue?vue&type=script&lang=js&"
export * from "./policies-combobox.vue?vue&type=script&lang=js&"
import style0 from "./policies-combobox.vue?vue&type=style&index=0&id=1fde3e5d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fde3e5d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VListItem,VListItemContent,VSubheader})
